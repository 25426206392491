import styled from 'styled-components'

export const Wrapper = styled.div`
  padding-top: 30px;
`

export const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  margin-bottom: 20px;
`

export const Curriculums = styled.div``

export const Curriculum = styled.div``
