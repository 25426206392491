import React from "react"
import Curriculum from "../Curriculum"
import styled from "styled-components"
import { Droppable } from "react-beautiful-dnd"
import { colors } from "../../../theme"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  min-height: 60px;
  background-color: ${props =>
    props.draggingOver ? colors.lightGray : colors.lighterGray};
  border-radius: 8px;
  padding: 10px;
  > * {
    margin: 5px 0;
  }
`

const Curriculums = ({ droppableId, curriculums, withNumbers }) => {
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <Wrapper ref={provided.innerRef} draggingOver={snapshot.isDraggingOver}>
          {curriculums.map((curriculum, i) => (
            <Curriculum key={curriculum._id} {...curriculum} index={i} showIndex={withNumbers}/>
          ))}
          {provided.placeholder}
        </Wrapper>
      )}
    </Droppable>
  )
}

Curriculums.propTypes = {}

Curriculums.defaultProps = {}

export default Curriculums
