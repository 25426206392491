import { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import GET_CURRICULUM_GROUP from './getCurriculumGroup.graphql'
import GET_CURRICULUMS from './getCurriculums.graphql'
import { createContainer, useContainer } from 'unstated-next'
import { AppState } from '../state/AppState'
import ADD_CURRICULUM_TO_GROUP from './addCurriculumToGroup.graphql'
import MOVE_CURRICULUM from './moveCurriculum.graphql'
import REMOVE_CURRICULUM_FROM_GROUP from './removeCurriculumFromGroup.graphql'

export const useCurriculumGroup = () => {
  const client = useApolloClient()
  const [curriculumGroup, setCurriculumGroup] = useState({})
  const [curriculums, setCurriculums] = useState([])
  const [id, setId] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!id) return
    fetch()
  }, [id])

  const fetch = async () => {
    setLoading(true)
    const { data } = await client.query({
      query: GET_CURRICULUM_GROUP,
      variables: { _id: id },
      fetchPolicy: 'network-only'
    })
    const { data: curriculumsData } = await client.query({
      query: GET_CURRICULUMS,
      fetchPolicy: 'network-only'
    })
    setCurriculumGroup(data.curriculumGroup)
    let groupCurriculumIds = data.curriculumGroup.curriculums.map(c => c._id)
    let newCurriculums = [
      ...curriculumsData.curriculums
    ].filter(c => groupCurriculumIds.indexOf(c._id) < 0)
    setCurriculums(newCurriculums)
    setLoading(false)
  }

  const removeCurriculumFromCurriculums = (sourceIndex) => {
    let newCurriculums = [...curriculums]
    let removedCurriculum = newCurriculums.splice(sourceIndex, 1)[0]
    setCurriculums(newCurriculums)
    return removedCurriculum
  }

  const addCurriculumToGroup = async (
    sourceIndex,
    targetIndex
  ) => {
    let curriculum = removeCurriculumFromCurriculums(sourceIndex)
    let groupCurriculums = [...curriculumGroup.curriculums]
    groupCurriculums.splice(targetIndex, 0, curriculum)
    setCurriculumGroup({ ...curriculumGroup, curriculums: groupCurriculums })
    await client.mutate({
      mutation: ADD_CURRICULUM_TO_GROUP,
      variables: {
        curriculumGroupId: curriculumGroup._id,
        curriculumId: curriculum._id,
        index: targetIndex,
      },
    })
  }

  const moveCurriculum = async (sourceIndex, targetIndex) => {
    let groupCurriculums = [...curriculumGroup.curriculums]
    let curriculum = groupCurriculums.splice(sourceIndex, 1)[0]
    groupCurriculums.splice(targetIndex, 0, curriculum)
    setCurriculumGroup({ ...curriculumGroup, curriculums: groupCurriculums })
    await client.mutate({
      mutation: MOVE_CURRICULUM,
      variables: {
        curriculumGroupId: curriculumGroup._id,
        curriculumId: curriculum._id,
        fromIndex: sourceIndex,
        toIndex: targetIndex,
      },
    })
  }

  const removeCurriculumFromGroup = async sourceIndex => {
    let groupCurriculums = [...curriculumGroup.curriculums]
    let curriculum = groupCurriculums.splice(sourceIndex, 1)[0]
    setCurriculumGroup({ ...curriculumGroup, curriculums: groupCurriculums })
    await client.mutate({
      mutation: REMOVE_CURRICULUM_FROM_GROUP,
      variables: { curriculumGroupId: curriculumGroup._id, curriculumId: curriculum._id },
    })
  }

  return { setId, curriculumGroup, curriculums, addCurriculumToGroup, removeCurriculumFromGroup, moveCurriculum, loading }
}

export const CurriculumGroupState = createContainer(useCurriculumGroup)
