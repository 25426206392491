import React from 'react'
import Layout from '../components/layout'
import CurriculumGroup from '../components/CurriculumGroup'

const CurriculumGroupPage = ({ id }) => (
  <Layout>
    <CurriculumGroup id={id} />
  </Layout>
)

export default CurriculumGroupPage
