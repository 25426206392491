import React from "react"
import styled from "styled-components"
import { Draggable } from "react-beautiful-dnd"

const Wrapper = styled.div`
  display: flex;
  padding: 0 15px;
  height: 40px;
  width: 100%;
  align-items: center;
  background-color: tomato;
  color: white;
  border-radius: 6px;
  > :first-child {
  }
  > :nth-child(2) {
    flex: 1;
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 100%;
    width: 40px;
  }
`

const Index = styled.div`
    margin-right: 15px;
    width: 50px;
    font-size: 15px;
    font-weight: bold;
    ::first-letter {
      font-size: 20px;
    }
`

const Curriculum = ({ _id, title, index, showIndex }) => {
  return (
    <Draggable key={_id} draggableId={_id} index={index}>
      {(provided, snapshot) => (
        <Wrapper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{
            ...provided.draggableProps.style
          }}
        >
          { showIndex && <Index>#{index + 1}</Index> }
          <div>{title}</div>
        </Wrapper>
      )}
    </Draggable>
  )
}

Curriculum.propTypes = {}

Curriculum.defaultProps = {}

export default Curriculum
