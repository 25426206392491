import React, { useEffect } from 'react'
import { Wrapper, Title } from './index.styles'
import { CurriculumGroupState } from './useCurriculumGroup'
import { Add } from 'grommet-icons'
import Curriculums from './Curriculums'
import Curriculum from './Curriculum'
import { DragDropContext } from 'react-beautiful-dnd'
import { useContainer } from 'unstated-next'

const CurriculumGroup = ({ id }) => {
  const { setId, curriculumGroup, curriculums, loading } = useContainer(CurriculumGroupState)
  useEffect(() => {
    if (!id) return
    setId(id)
  }, [])
  if (loading) return <div>Loading...</div>
  
  return <Wrapper>
    <Title>{ curriculumGroup.title }</Title>
    <Curriculums curriculums={curriculumGroup.curriculums} droppableId='curriculum-group' withNumbers />
    <Curriculums curriculums={curriculums} droppableId='curriculums' />
  </Wrapper>
}

CurriculumGroup.propTypes = {

}

CurriculumGroup.defaultProps = {

}

export const DragDropContainer = ({ children }) => {
  const { addCurriculumToGroup, moveCurriculum, removeCurriculumFromGroup } = useContainer(CurriculumGroupState)
  const onDragEnd = (e) => {
    const { destination, source } = e
    if (!source || !destination) return
    if (source.droppableId !== 'curriculum-group' && destination.droppableId === 'curriculum-group') {
      addCurriculumToGroup(source.index, destination.index)
    }
    if (source.droppableId === 'curriculum-group' && destination.droppableId === 'curriculum-group') {
      moveCurriculum(source.index, destination.index)
    }
    if (source.droppableId === 'curriculum-group' && destination.droppableId !== 'curriculum-group') {
      removeCurriculumFromGroup(source.index, destination.index)
    }
  }

  return <DragDropContext onDragEnd={onDragEnd}>
    { children }
  </DragDropContext>
}

export default (props) => (
  <CurriculumGroupState.Provider>
    <DragDropContainer>
      <CurriculumGroup {...props} />
    </DragDropContainer>
  </CurriculumGroupState.Provider>
)
